<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <h3>Manage {{ module_name }}</h3>
                    </template>
                    <template v-slot:end>
                        <!-- <router-link v-if="permissions[30]" to="/customers/manage-customer-categories">
                            <Button v-tooltip.top="'Manage Customer '" label="Manage Customer Categories" icon="pi pi-arrow-up-right" class="p-button-raised p-button-md p-button-warning mr-2" />
                        </router-link> -->
                    </template>
                </Toolbar>
                <Toolbar class="pl-0 pr-0">
                    <template v-slot:start>
                        <Button v-if="checkPermission(moduleId, 'insert')" v-tooltip.top="'Add New'"
                            :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-primary mr-2" />
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Refresh'"
                            :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-success mr-2" @click="getRecords" />
                        <Button v-if="checkPermission(moduleId, 'export')" v-tooltip.top="'Export'"
                            icon="pi pi-external-link"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''"
                            @click="exportCSV($event)" />

                        <Button v-if="checkPermission(moduleId, 'delete')" v-tooltip.top="'Delete Selected'"
                            :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-danger mr-2" @click="deleteRecord"
                            :disabled="!selectedRecords || !selectedRecords.length" />
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Remove Filters'" type="button"
                            icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2" @click="clearFilter()" />
                        <Button v-if="!filterFormDisplay" v-tooltip.top="'Filter Records'"
                            :label="showBtnLabels ? 'Filter Records' : ''" icon="pi pi-filter"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-helper mr-2" @click="filterFormDisplay = !filterFormDisplay" />
                    </template>
                    <template v-slot:end>
                        <Button v-if="checkPermission(moduleId, 'view')" v-tooltip.top="'Helping Material'"
                            :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question"
                            :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                            class="p-button-raised p-button-info mr-2" />
                    </template>
                </Toolbar>
                <Divider class="mb-0" v-if="savedSearches.length" />
                <Toolbar class="pl-0 pr-0" v-if="savedSearches.length" style="flex-direction:column">
                    <template v-slot:start>
                        <Tag v-for="(search, key) in savedSearches" :key="key" class="p-button-raised mr-2 secondary">
                            <div class="flex align-items-center gap-2 p-2 cursor"
                                @click="filterForm.search_id = search.id; getRecords();">
                                <span class="text-base">{{ search.name }}</span>
                            </div>
                            <i class="pi pi-times text-lg cursor" @click="deleteSavedSearch(search.id)"></i>
                        </Tag>

                    </template>
                </Toolbar>
                <Panel class="mt-3 mb-3" v-if="filterFormDisplay">
                    <template #header>
                        <h3>Filters</h3>
                    </template>
                    <div class="col-12 ">
                        <form @submit.prevent="prevent" @keydown="form.onKeydown($event)" enctype="multipart/form-data"
                            class="grid">

                            <div class="col-2">
                                <label>Traveling Dates</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.traveling_date" :options="quickDates" :placeholder="'Select Date'"
                                    class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="traveling_date" />
                            </div>
                            <div class="col-2" v-if="filterForm.traveling_date == 'Custom'">
                                <label>Traveling Date</label>
                                <Calendar selectionMode="range" :manualInput="false" :dateFormat="calendarFormat()"
                                    :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                    id="filter_order_date" v-model="filterForm.traveling_custom_date" :showTime="false"
                                    :showSeconds="false" class="full-width" />
                            </div>
                            <div class="col-2">
                                <label>Order Booking Dates</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.order_booking_date" :options="quickDates"
                                    :placeholder="'Select Date'" class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="order_booking_date" />
                            </div>
                            <div class="col-2" v-if="filterForm.order_booking_date == 'Custom'">
                                <label>Order Booking Date</label>
                                <Calendar selectionMode="range" :manualInput="false" :dateFormat="calendarFormat()"
                                    :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"
                                    id="filter_order_date" v-model="filterForm.order_booking_custom_date" :showTime="false"
                                    :showSeconds="false" class="full-width" />
                            </div>
                            <div class="col-2">
                                <label> Order Status</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.order_status" :options="orderStatusesArray"
                                    :placeholder="'Select Status'" class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="order_status" />
                            </div>
                            <div class="col-2">
                                <label> Order Move Status</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.order_move_status" :options="orderMovesStatusesArray"
                                    :placeholder="'Select Status'" class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="order_move_status" />
                            </div>
                            <div class="col-2">
                                <label> Driver Request Status</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.order_driver_request_status" :options="driverRequestStatusesArray"
                                    :placeholder="'Select Status'" class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="order_driver_request_status" />
                            </div>
                            <div class="col-3">
                                <label>Driver</label>
                                <Dropdown v-model="filterForm.driver_id" :options="drivers" driversLoading
                                    :filterFields="['name', 'designation']" optionLabel="name" :filter="true"
                                    placeholder="Select Driver" :showClear="true" class="full-width">
                                    <template #value="slotProps">
                                        <div class="country-item country-item-value" v-if="slotProps.value">
                                            <div style="display: flex">
                                                <img :src="slotProps.value.img" style="border-radius: 2%; width:30px" />
                                                <span style="padding-left: 5px"><strong>{{ slotProps.value.name
                                                }}</strong><span v-if="slotProps.value.user_category_name"> ({{
    slotProps.value.user_category_name }}) </span><br />
                                                    {{ slotProps.value.email }}</span>
                                            </div>
                                        </div>
                                        <span v-else>
                                            {{ slotProps.placeholder }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="country-item">
                                            <div style="display: flex">
                                                <img v-if="slotProps.option.profile_pic"
                                                    :src="filesUrl + 'users/' + slotProps.option.profile_pic"
                                                    style="border-radius: 2%; width:30px" />
                                                <img v-else :src="filesUrl + 'defaults/default.png'"
                                                    style="border-radius: 2%; width:30px;" />
                                                <span style="padding-left: 5px"><strong>{{ slotProps.option.name
                                                }}</strong><span v-if="slotProps.option.user_category_name"> ({{
    slotProps.option.user_category_name }}) </span><br />
                                                    {{ slotProps.option.email }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                            <div class="col-3">
                                <label> Customer</label>
                                <Dropdown v-model="filterForm.customer_id" :options="customers" customersLoading
                                    :filterFields="['name', 'designation']" optionLabel="name" :filter="true"
                                    placeholder="Select Customer" :showClear="true" class="full-width">
                                    <template #value="slotProps">
                                        <div class="country-item country-item-value" v-if="slotProps.value">
                                            <div style="display: flex">
                                                <img :src="slotProps.value.img" style="border-radius: 2%; width:30px" />
                                                <span style="padding-left: 5px"><strong>{{ slotProps.value.name
                                                }}</strong><span v-if="slotProps.value.user_category_name"> ({{
    slotProps.value.user_category_name }}) </span><br />
                                                    {{ slotProps.value.email }}</span>
                                            </div>
                                        </div>
                                        <span v-else>
                                            {{ slotProps.placeholder }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="country-item">
                                            <div style="display: flex">
                                                <img v-if="slotProps.option.profile_pic"
                                                    :src="filesUrl + 'users/' + slotProps.option.profile_pic"
                                                    style="border-radius: 2%; width:30px" />
                                                <img v-else :src="filesUrl + 'defaults/default.png'"
                                                    style="border-radius: 2%; width:30px;" />
                                                <span style="padding-left: 5px"><strong>{{ slotProps.option.name
                                                }}</strong><span v-if="slotProps.option.user_category_name"> ({{
    slotProps.option.user_category_name }}) </span><br />
                                                    {{ slotProps.option.email }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </Dropdown>
                            </div>
                            <div class="col-4 grid">
                                <div class="col-6">
                                    <label>Order Price From</label>
                                    <InputNumber id="filter_order_price_from" placeholder="From"
                                        v-model="filterForm.order_price_from" :showButtons="true" :min="0"
                                        class="full-width" />
                                </div>
                                <div class="col-6">
                                    <label>Order Price To</label>
                                    <InputNumber id="filter_order_price_to" placeholder="To"
                                        v-model="filterForm.order_price_to" :showButtons="true" :min="0"
                                        class="full-width" />
                                </div>
                            </div>
                            <Divider />
                            <div class="col-2">
                                <label>Save Search</label>
                                <Dropdown :disabled="viewableOnly" :filter="true" :showClear="true"
                                    v-model="filterForm.save_search" :options="saveSearchOptions"
                                    :placeholder="'Select Option'" class="full-width" optionLabel="name" optionValue="id" />
                            </div>
                            <div class="col-3" v-if="filterForm.save_search">
                                <label>Save as</label>
                                <InputText id="save_as" placeholder="Save as" v-model="filterForm.save_as"
                                    class="full-width" />
                                <HasError class="p-error" :form="filterForm" field="save_as" />
                            </div>
                            <Divider />
                            <div class="col-12">
                                <Button type="submit" label="Filter Records" :loading="isFiltering" icon="pi pi-check"
                                    @click="filterFormDisplay = false, getRecords()"
                                    :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                    class="p-button-raised  p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                    class="p-right p-button-raised p-button-warning mr-2" @click="resetFilterForm()" />
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                    class="p-right p-button-raised p-button-danger mr-2"
                                    @click="filterFormDisplay = false, resetFilterForm()" />
                            </div>
                        </form>
                    </div>
                </Panel>
                <DataTable :reorderableColumns="true" @columnReorder="onColReorder" :resizableColumns="true"
                    columnResizeMode="fit" ref="dt" :value="records" :paginator="true" sortMode="multiple"
                    class="p-datatable-gridlines p-datatable-sm" :rows="10" dataKey="id" :rowHover="true" :loading="loading"
                    :filters="filters" responsiveLayout="scroll"
                    :globalFilterFields="['order_id', 'order_status', 'driver_name', 'driver_email', 'driver_phone', 'driver_acceptance_status', 'customer_name', 'customer_email', 'customer_phone', 'vehicle_name', 'vehicle_type_name', 'departure_address', 'destination_address', 'added_by', 'modified_by', 'date_added', 'status']"
                    :rowsPerPageOptions="[10, 20, 50, 100]"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    v-model:selection="selectedRecords" selectionMode="multiple" :scrollable="true" scrollHeight="400px">

                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search"
                                    style="width: 100%" />
                            </span>
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                                @update:modelValue="onToggle" placeholder="Select Columns" style="width: 20em" />
                        </div>
                    </template>

                    <template #empty>
                        <div class="table-message">
                            <img class="table-message-img" src="layout/images/no-record-found.png" alt="No record found">
                        </div>
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>

                    <Column selectionMode="multiple" style="max-width:3rem;flex-grow:1; flex-basis:160px"></Column>
                    <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                        :exportable="col.field == 'action' ? false : true" :sortable="col.field == 'action' ? false : true"
                        :key="col.field + '_' + index" :class="col.field == 'action' ? 'btnAction' : ''"
                        :style="col.field !== 'action' ? 'min-width:12rem;flex-grow:1; flex-basis:160px;' : 'max-width:5rem;flex-grow:1; flex-basis:160px;'">
                        <template #body="{ data }">
                            <img v-if="col.field == 'company' && data.logo" :alt="data.company"
                                :src="filesUrl + 'companies/' + data.logo" class="tableProfileImg mr-2" />
                            <img v-if="col.field == 'company' && !data.logo" :alt="data.company"
                                :src="filesUrl + 'defaults/default-logo.png'" class="tableProfileImg mr-2" />
                            <span v-if="col.field == 'company'">{{ data.company }}</span>
                            <span v-if="col.field == 'order_number'"
                                @click="selectedRecordId = data.id, getClickedRecord('view')">#{{ data.order_number }}
                                &nbsp;
                                &nbsp; &nbsp; &nbsp; &nbsp; <span v-if="data.total_schedules" class="pi pi-clock"
                                    style="float:right"></span></span>

                            <img @click="showUserProfile(data.driver_id)"
                                v-if="col.field == 'driver_name' && data.driver_profile_pic && data.driver_name" alt=""
                                :src="filesUrl + 'users/' + data.driver_profile_pic" class="tableProfileImg mr-2" />
                            <img @click="showUserProfile(data.driver_id)"
                                v-if="col.field == 'driver_name' && !data.driver_profile_pic && data.driver_name" alt=""
                                :src="filesUrl + 'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span @click="showUserProfile(data.driver_id)"
                                v-if="col.field == 'driver_name' && data.driver_name">
                                <strong>{{ data.driver_name }}</strong><br /><small>{{ data.driver_phone }}</small><br />
                                <Tag v-if="data.driver_acceptance_status && driverRequestStatuses[data.driver_acceptance_status]"
                                    class="mr-2" :class="driverRequestStatuses[data.driver_acceptance_status].class"
                                    :icon="driverRequestStatuses[data.driver_acceptance_status].icon"
                                    :severity="driverRequestStatuses[data.driver_acceptance_status].color">
                                    <div class="status-content">
                                        <span>{{ data.driver_acceptance_status }}</span>
                                        <span v-if="data.driver_request_status_change_date">Last Modified: {{
                                            formatDate(data.driver_request_status_change_date) }}</span>
                                    </div>
                                </Tag>
                            </span>

                            <img @click="showUserProfile(data.customer_name)"
                                v-if="col.field == 'customer_name' && data.customer_profile_pic && data.customer_name"
                                alt="" :src="filesUrl + 'users/' + data.customer_profile_pic"
                                class="tableProfileImg mr-2" />
                            <img @click="showUserProfile(data.customer_name)"
                                v-if="col.field == 'customer_name' && !data.customer_profile_pic && data.customer_name"
                                alt="" :src="filesUrl + 'defaults/default.png'" class="tableProfileImg mr-2" />
                            <span @click="showUserProfile(data.customer_name)"
                                v-if="col.field == 'customer_name'"><strong>{{ data.customer_name }}</strong><br /><small>{{
                                    data.customer_email }}</small></span>
                            <img v-if="col.field == 'vehicle_name' && data.vehicle_image && data.vehicle_name" alt=""
                                :src="filesUrl + 'vehicles/' + data.vehicle_image" class="tableProfileImg mr-2" />
                            <i v-if="col.field == 'vehicle_name' && !data.vehicle_image && data.vehicle_name"
                                class="tableProfileImg mr-2 pi pi-fw pi-car"></i>
                            <span v-if="col.field == 'vehicle_name'"><strong>{{ data.vehicle_name }}</strong><br /><small>{{
                                data.vehicle_type_name }}</small></span>
                            <span v-if="col.field == 'order_id'">#{{ data.order_id }}
                                <span v-if="data.order_status">
                                    <br />
                                    <Tag class="mr-2 " :class="orderStatuses[data.order_status].class"
                                        :icon="orderStatuses[data.order_status].icon"
                                        :severity="orderStatuses[data.order_status].color">
                                        <div class="status-content">
                                            <span>{{ data.order_status }}</span>
                                            <span v-if="data.order_status_changed_date">Last Modified: {{
                                                formatDate(data.order_status_changed_date) }}</span>
                                        </div>
                                    </Tag>
                                </span>
                            </span>
                            <span v-if="col.field == 'price'"><strong>{{ userData['company_currency_code'] ?
                                userData['company_currency_code'] : '$' }} {{ data.journey_price }}</strong> <span
                                    v-if="data.journey_price != data.price"><br> <small>Order Price :
                                        {{ data.order_price }}</small> <br> <small>Price Change :
                                        {{ data.price_change }}</small></span> </span>
                            <span v-if="col.field == 'start_journey'">
                                <div v-if="data.departure_address">
                                    <Button type="button" label="Journey Routes" :id="data.id" @click="togglePopover"
                                        class="p-button-success" style="background-color:black" />
                                    <OverlayPanel :ref="'op' + data.id" appendTo="body" :showCloseIcon="true"
                                        id="overlay_panel" style="width: 450px"
                                        :breakpoints="{ '960px': '75vw', '640px': '100vw' }">
                                        <div v-if="routesLoading" class="loader">
                                            <span class="pi pi-spin pi-spinner"></span>
                                        </div>
                                        <div v-else>
                                            <div v-if="data.departure_address">
                                                <div class="mb-3">
                                                    <div class="card mb-1">
                                                        <div class="col-12">
                                                            <strong>From</strong>
                                                            {{ data.departure_address }}
                                                            <span class="ml-2" v-if="data.departure_state">{{
                                                                data.departure_state
                                                            }}</span>
                                                            <span class="ml-2" v-if="data.departure_city">{{
                                                                data.departure_city
                                                            }}</span>
                                                            <span class="ml-2" v-if="data.departure_zipcode">{{
                                                                data.departure_zipcode
                                                            }}</span>
                                                        </div>
                                                        <div class="col-12">
                                                            <strong>To</strong>
                                                            {{ data.destination_address }}
                                                            <span class="ml-2" v-if="data.destination_state">{{
                                                                data.destination_state
                                                            }}</span>
                                                            <span class="ml-2" v-if="data.destination_city">{{
                                                                data.destination_city
                                                            }}</span>
                                                            <span class="ml-2" v-if="data.destination_zipcode">{{
                                                                data.destination_zipcode }}</span>
                                                        </div>
                                                        <div class="col-12">
                                                            <span v-if="data.legs && data.legs > 2"><strong>Stops:</strong>
                                                                {{ (data.legs - 2) }}</span><br />
                                                            <span v-if="data.passangers"><strong>Passangers:</strong> {{
                                                                data.passangers }}</span><br />
                                                            <span v-if="data.hand_luggage"><strong>Hand Luggage:</strong> {{
                                                                data.hand_luggage }}</span><br />
                                                            <span v-if="data.suitcases"><strong>Suitcases:</strong> {{
                                                                data.suitcases }}</span><br />
                                                            <span v-if="data.child_seats"><strong>Child Seats:</strong> {{
                                                                data.child_seats }}</span><br />
                                                        </div>
                                                        <div class="col-12">
                                                            <span v-if="data.notes"><strong>Notes:</strong>
                                                                {{ data.notes }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <h4>No Routes Found!</h4>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                    <span v-if="data.move_status">
                                        <br />
                                        <Tag class="mr-2 mt-2" :icon="orderMoveStatuses[data.move_status].icon"
                                            :severity="orderMoveStatuses[data.move_status].color">
                                            <div class="status-content">
                                                <span>{{ data.move_status }}</span>
                                                <span v-if="data.order_move_status_changed_date">Last Modified: {{
                                                    formatDate(data.order_move_status_changed_date)
                                                }}</span>
                                            </div>
                                        </Tag>
                                    </span>
                                </div>
                                <span v-else>N/A</span>
                            </span>
                            <span v-if="col.field == 'start_time'">
                                <span v-if="data.start_time">
                                    <strong>Start Time:</strong>{{ formatDateTime(data.start_time) }}<br />
                                    <strong v-if="data.end_time">End Time:</strong>{{ formatDateTime(data.end_time) }}
                                </span>
                                <span v-else>N/A</span>
                            </span>
                            <span v-else-if="col.field == 'date_added'">{{ formatDateTime(data.date_added) }}</span>
                            <span v-else-if="col.field == 'added_by'">
                                <span v-if="data.added_by">{{ data.added_by }}</span>
                                <span v-else>System</span>
                            </span>
                            <span v-else-if="col.field == 'modified_by'">{{ data.modified_by }}</span>
                            <span v-else-if="col.field == 'date_modification'">{{ formatDateTime(data.date_modification)
                            }}</span>

                            <span v-else-if="col.field == 'action'">
                                <div :class="{ 'ml-auto': !isRTL, 'mr-auto': isRTL }">
                                    <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text"
                                        :recordId="data.id" :orderId="data.order_id" @click="toggleRecordMenu"></Button>
                                    <Menu ref="menu" :popup="true" :model="actions"></Menu>

                                </div>
                            </span>
                        </template>
                    </Column>
                </DataTable>
            </div>

        </div>

        <Sidebar v-model:visible="showStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true" @hide="showStatusChangedForm = false; removeViewFromURL('change-order-status')"
            position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="orderStatusForm.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Order#{{ selectedRecordId }}</h3>
                        <p>Update order status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <h4>Order status is being changed to <span style="color:red">"{{ orderStatusForm.status
                                    }}"</span></h4>
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>
                                        <h5>Status Change Description</h5>
                                    </label>
                                    <Textarea placeholder="Description" v-model="orderStatusForm.description" rows="5"
                                        cols="30" class="full-width" />
                                    <HasError class="p-error" :form="orderStatusForm" field="description" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isStatusSaving" icon="pi pi-check"
                                    @click="changeOrderStatus()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" :loading="isStatusSaving" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="showStatusChangedForm = false; removeViewFromURL('change-order-status')" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>

        <Sidebar v-model:visible="showOrderMoveStatusChangedForm" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="true"
            @hide="showOrderMoveStatusChangedForm = false; removeViewFromURL('change-order-move-status')" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="orderMoveStatusForm.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Order#{{ selectedOrderId }}</h3>
                        <p>Update order move status with reason </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <h4>Order move status is being changed to <span style="color:red">"{{
                                        orderMoveStatusForm.status
                                    }}"</span></h4>
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>
                                        <h5>Status Change Description</h5>
                                    </label>
                                    <Textarea placeholder="Description" v-model="orderMoveStatusForm.description" rows="5"
                                        cols="30" class="full-width" />
                                    <HasError class="p-error" :form="orderMoveStatusForm" field="description" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Change Status" :loading="isOrderMoveStatusSaving"
                                    icon="pi pi-check" @click="changeOrderMoveStatus()"
                                    class="p-button-raised p-button-lg p-button-success mr-2" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" :loading="isOrderMoveStatusSaving" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="showOrderMoveStatusChangedForm = false; removeViewFromURL('change-order-move-status')" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>

        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <OrdersForm @closeOrderFrom="closeForm"
            v-if="showForm && (checkPermission(moduleId, 'insert') || checkPermission(moduleId, 'update'))"
            :orderView="orderView" :recordId="selectedOrderId" :showForm="true" />
        <ModuleHelp v-on:closeHelp="showHelp = false; removeViewFromURL('view-help')"
            v-if="showHelp && checkPermission(moduleId, 'view')" :moduleId="moduleId" />
        <UserProfileView v-on:UserProfileClose="showUserProfileView = false; removeViewFromURL('view-user-profile')"
            v-if="showUserProfileView && (permissions[5].includes('insert') || permissions[5].includes('update'))"
            :userType="'employee'" :recordId="selectedUserId" :showForm="true" />
    </div>
</template>
<script>

import UserProfileView from '../../components/AccessManagement/UserProfileView.vue';
import OrdersForm from '../../components/Orders/OrdersForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            filterForm: new this.Form({
                module_name: 'orders',
                order_booking_custom_date: '',
                order_booking_date: '',
                traveling_date: '',
                traveling_custom_date: '',
                order_status: '',
                order_move_status: '',
                order_driver_request_status: '',
                customer_id: 0,
                driver_id: 0,
                order_price_from: '',
                order_price_to: '',
                save_search: 0,
                save_as: '',
            }),
            orderStatusForm: new this.Form({
                id: 0,
                description: '',
                status: '',
            }),
            orderMoveStatusForm: new this.Form({
                id: 0,
                order_id: 0,
                description: '',
                status: '',
            }),
            module_name: 'Orders',
            actions: [{ 'label': 'Order Actions', items: [] }, { 'label': 'Move Actions', items: [] }],
            quickDates: ['Custom', 'Today', 'Yesterday', 'This Week', 'Last Week', 'This Month', 'Last Month', 'Last 2 Months', 'Last 3 Months', 'Last 4 Months', 'Last 5 Months', 'Last 6 Months', 'This Year', 'Last Year'],
            orderStatusesArray: ['New', 'Quoted', 'Confirmed', 'InProcess', 'Completed', 'Canceled'],
            orderMovesStatusesArray: ['Pending', 'InProcess', 'Completed', 'Canceled'],
            driverRequestStatusesArray: ['New', 'Pending', 'Confirmed', 'Completed', 'Rejected', 'Canceled', 'UnAssigned'],
            moduleId: 40,
            showHelp: false,
            showUserProfileView: false,
            filterFormDisplay: false,
            isFiltering: false,
            isSaving: false,
            isStatusSaving: false,
            routesLoading: false,
            records: null,
            filters: null,
            loading: true,
            selectedRecords: null,
            columns: null,
            selectedColumns: null,
            showForm: false,
            selectedRecordId: 0,
            selectedOrderId: 0,
            selectedUserId: 0,
            saveAndClose: false,
            showStatusChangedForm: false,
            isOrderMoveStatusSaving: false,
            orderCategoriesLoading: false,
            showOrderMoveStatusChangedForm: false,
            saveSearchOptions: [{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }],
            newOrderStatus: '',
            orderCategories: [],
            orderStatuses: [],
            driverRequestStatuses: [],
            orderMoveStatuses: [],
            orderRoutes: [],
            customers: [],
            drivers: [],
            savedSearches: [],
            orderView: false,
            customersLoading: false,
            driversLoading: false,
        }
    },
    components: {
        OrdersForm,
        ModuleHelp,
        UserProfileView,
    },
    beforeCreate() {



    },
    created() {
        if (this.checkPermission(this.moduleId, 'update')) {
            this.actions[0].items.push({
                label: 'Update',
                icon: 'pi pi-refresh',
                command: () => {
                    this.getClickedRecord('update');
                }
            });
        }
        if (this.checkPermission(this.moduleId, 'view')) {
            this.actions[0].items.push({
                label: 'View',
                icon: 'pi pi-eye',
                command: () => {
                    this.getClickedRecord('view');
                }
            });
        }
        if (this.checkPermission(this.moduleId, 'delete')) {
            this.actions[0].items.push({
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => {
                    this.getClickedRecord('delete');
                }
            });
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.actions[0].items.push(
                { separator: true },
                { label: 'New', icon: 'pi pi-eye-slash', command: () => { this.getClickedRecord('New'); } },
                { label: 'Quoted', icon: 'pi pi-file', command: () => { this.getClickedRecord('Quoted'); } },
                { label: 'Confirmed', icon: 'pi pi-check', command: () => { this.getClickedRecord('Confirmed'); } },
                { label: 'InProcess', icon: 'pi pi-history', command: () => { this.getClickedRecord('InProcess'); } },
                { label: 'Completed', icon: 'pi pi-check-circle', command: () => { this.getClickedRecord('Completed'); } },
                { label: 'Canceled', icon: 'pi pi-times', command: () => { this.getClickedRecord('Canceled'); } }
            );
        }
        if (this.checkPermission(this.moduleId, 'update')) {
            this.actions[1].items.push(
                { separator: true },
                { label: 'Pending', icon: 'pi pi-eye-slash', command: () => { this.getClickedRecord('MovePending'); } },
                { label: 'InProcess', icon: 'pi pi-history', command: () => { this.getClickedRecord('MoveInProcess'); } },
                { label: 'Completed', icon: 'pi pi-check-circle', command: () => { this.getClickedRecord('MoveCompleted'); } },
                { label: 'Canceled', icon: 'pi pi-times', command: () => { this.getClickedRecord('MoveCanceled'); } }
            );
        }
        this.initFilters();
        this.columns = [
            { field: 'action', header: 'Action' },
            { field: 'order_id', header: 'Order Info#' },
            { field: 'company', header: 'Company' },
            { field: 'customer_name', header: 'Customer Info' },
            { field: 'start_journey', header: 'Traveling Info' },
            { field: 'driver_name', header: 'Driver Info' },
            { field: 'vehicle_name', header: 'Vehicle Info' },
            { field: 'start_time', header: 'Traveling Dates' },
            { field: 'price', header: 'Journey Price' },
            { field: 'added_by', header: 'Created By' },
            { field: 'date_added', header: 'Date Added' },
            { field: 'modified_by', header: 'Last Modified By' },
            { field: 'date_modification', header: 'Date Modification' },
        ];
        this.selectedColumns = this.columns;
    },
    computed: {
    },
    mounted() {
        if (this.$route.query.view) {
            this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
            if (Object.keys(this.urlParams).length) {
                this.urlParams.forEach((parameter) => {
                    if (parameter.view == 'add-order') {
                        this.selectedOrderId = parameter.id;
                        this.showForm = true;
                    }
                    if (parameter.view == 'view-order') {
                        this.selectedRecordId = parameter.id;
                        this.showForm = true;
                        this.orderView = true;
                    }
                    else if (parameter.view == 'view-order-user-profile') {
                        this.selectedUserId = parameter.id;
                        this.showProfileView = true;
                    }
                    else if (parameter.view == 'view-user-profile') {
                        this.selectedUserId = parameter.id;
                        this.showUserProfileView = true;
                    }
                    else if (parameter.view == 'view-help') {
                        this.selectedRecordId = parameter.id;
                        this.showHelp = true;
                    }
                    else if (parameter.view == 'change-order-status') {
                        this.selectedRecordId = parameter.id;
                        this.orderStatusForm.reset();
                        this.showStatusChangedForm = true;
                        this.orderStatusForm.status = parameter.order_status;
                    }
                    else if (parameter.view == 'change-order-move-status') {
                        this.selectedRecordId = parameter.id;
                        this.selectedOrderId = parameter.order_id;
                        this.orderMoveStatusForm.reset();
                        this.showOrderMoveStatusChangedForm = true;
                        this.orderMoveStatusForm.status = parameter.order_move_status;
                    }
                })
            }
        }




        //THIS CODE IS TO TABLE COLUMNS SETTINGS
        this.axios.post('getAllModuleColumnSettings', { 'module_id': this.moduleId })
            .then((response) => {
                if (response.data) {
                    if (Object.keys(response.data).length) {
                        this.columns = [];
                        this.selectedColumns = [];
                        response.data.forEach((column) => {
                            this.columns.push({ 'field': column.column_field, 'header': column.column_head });
                            if (column.display) {
                                this.selectedColumns.push({ 'field': column.column_field, 'header': column.column_head });
                            }
                        });
                    }
                }
            })
            .catch(() => {
            })
            .finally(() => {
            })
        //END

        this.getRecords();
        this.getOrderCategories();
        this.getCustomers();
        this.getDrivers();
        this.getSavedSearches();

        this.orderStatuses['New'] = { label: 'New', icon: 'pi pi-eye-slash', color: 'warning' };
        this.orderStatuses['Quoted'] = { label: 'Quoted', icon: 'pi pi-file', color: 'info' };
        this.orderStatuses['Confirmed'] = { label: 'Confirmed', icon: 'pi pi-check', color: 'help', class: 'help' };
        this.orderStatuses['InProcess'] = { label: 'InProcess', icon: 'pi pi-watch', color: 'secondary', class: 'secondary' };
        this.orderStatuses['Completed'] = { label: 'Completed', icon: 'pi pi-check-circle', color: 'success' };
        this.orderStatuses['Canceled'] = { label: 'Canceled', icon: 'pi pi-times', color: 'danger' };

        this.orderMoveStatuses['Pending'] = { label: 'New', icon: 'pi pi-eye-slash', color: 'warning' };
        this.orderMoveStatuses['InProcess'] = { label: 'InProcess', icon: 'pi pi-watch', color: 'info' };
        this.orderMoveStatuses['Completed'] = { label: 'Completed', icon: 'pi pi-check-circle', color: 'success' };
        this.orderMoveStatuses['Canceled'] = { label: 'Canceled', icon: 'pi pi-times', color: 'danger' };

        this.driverRequestStatuses['New'] = { label: 'New', icon: 'pi pi-eye-slash', color: 'info' };
        this.driverRequestStatuses['Pending'] = { label: 'Pending', icon: 'pi pi-eye-slash', color: 'warning' };
        this.driverRequestStatuses['Confirmed'] = { label: 'Confirmed', icon: 'pi pi-check', color: 'help', class: 'help' };
        this.driverRequestStatuses['Completed'] = { label: 'Completed', icon: 'pi pi-check-circle', color: 'success' };
        this.driverRequestStatuses['Rejected'] = { label: 'Rejected', icon: 'pi pi-times', color: 'danger' };
        this.driverRequestStatuses['Canceled'] = { label: 'Canceled', icon: 'pi pi-times', color: 'secondary', class: 'secondary' };
        this.driverRequestStatuses['UnAssigned'] = { label: 'UnAssigned', icon: 'pi pi-times', color: 'secondary', class: 'secondary' };
    },
    methods: {
        resetFilterForm() {
            this.filterForm.reset();
        },
        onColReorder(event) {
            this.columns = this.tableColumnOrderChange(event, this.columns);
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },
        onToggle(value) {
            //this.selectedColumns = this.columns.filter(col => value.includes(col)); 
            this.selectedColumns = [];
            let newColumns = [];
            this.columns.filter((col) => {
                if (value.find(x => x.field === col.field)) {
                    newColumns.push(col);
                    return col;
                }
            });
            this.selectedColumns = newColumns;
            this.saveTableColumnOrderChange(this.moduleId, this.columns, this.selectedColumns);
        },
        showUserProfile(userId) {
            this.selectedUserId = userId;
            this.showUserProfileView = true;
            this.addViewInURL({ view: 'view-user-profile', id: this.selectedUserId });
        },

        togglePopover(event) {
            let id = event.currentTarget.id;
            this.$refs['op' + id].toggle(event);

        },

        async getDrivers() {
            var Vthis = this;
            Vthis.driversLoading = true;
            await this.axios
                .post('getRoleSpecificUsers', { company_id: this.userData['company_id'], role: 'Driver', dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.drivers = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.driversLoading = false;
                });
        },

        async getCustomers() {
            var Vthis = this;
            Vthis.customersLoading = true;
            await this.axios
                .post('getRoleSpecificUsers', { company_id: this.userData['company_id'], role: 'Customer', dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.customers = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.customersLoading = false;
                });
        },

        async getOrderRoutes(order_id) {
            var Vthis = this;
            this.routesLoading = true;
            this.orderRoutes = [];
            await this.axios.post('getOrderRoutesInfo', { order_id: order_id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.orderRoutes = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.routesLoading = false;
                })
        },
        async changeOrderStatus() {
            var Vthis = this;
            Vthis.orderStatusForm.id = Vthis.selectedOrderId;
            this.isStatusSaving = true;
            await this.orderStatusForm
                .post(Vthis.$baseurl + 'api/changeOrderStatus', Vthis.orderStatusForm)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        // this.form.reset();    
                        Vthis.showMessage('Saved successfully', 'success');
                        this.showStatusChangedForm = false;
                        this.getRecords();
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isStatusSaving = false;
                })
        },

        async changeOrderMoveStatus() {
            var Vthis = this;
            Vthis.orderMoveStatusForm.id = Vthis.selectedRecordId;
            Vthis.orderMoveStatusForm.order_id = Vthis.selectedOrderId;

            this.isOrderMoveStatusSaving = true;
            await this.orderMoveStatusForm
                .post(Vthis.$baseurl + 'api/changeOrderMoveStatus', Vthis.orderMoveStatusForm)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        // this.form.reset();    
                        Vthis.showMessage('Saved successfully', 'success');
                        this.showOrderMoveStatusChangedForm = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isOrderMoveStatusSaving = false;
                })
        },


        getClickedRecord(action) {
            if (action == 'delete') {
                this.deleteRecord();
            }
            else if (action == 'view') {
                this.addViewInURL({ view: 'view-order', id: this.selectedRecordId, orderId: this.selectedOrderId });
                this.showForm = true;
                this.orderView = true;
            }
            else if (action == 'update') {
                this.addViewInURL({ view: 'add-order', id: this.selectedRecordId, orderId: this.selectedOrderId });
                this.showForm = true;
                this.orderView = false;
            }
            else if (action == 'New') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'New';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'New' });
            }
            else if (action == 'Quoted') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'Quoted';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'Quoted' });
            }
            else if (action == 'Confirmed') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'Confirmed';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'Confirmed' });
            }
            else if (action == 'InProcess') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'InProcess';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'InProcess' });
            }
            else if (action == 'Completed') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'Completed';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'Completed' });
            }
            else if (action == 'Canceled') {
                this.orderStatusForm.reset();
                this.showStatusChangedForm = true;
                this.orderStatusForm.status = 'Canceled';
                this.addViewInURL({ view: 'change-order-status', id: this.selectedOrderId, order_status: 'Canceled' });
            }
            else if (action == 'MovePending') {
                this.orderMoveStatusForm.reset();
                this.showOrderMoveStatusChangedForm = true;
                this.orderMoveStatusForm.status = 'Pending';
                this.addViewInURL({ view: 'change-move-order-status', id: this.selectedRecordId, order_id: this.selectedOrderId, order_move_status: 'InProcess' });
            }
            else if (action == 'MoveInProcess') {
                this.orderMoveStatusForm.reset();
                this.showOrderMoveStatusChangedForm = true;
                this.orderMoveStatusForm.status = 'InProcess';
                this.addViewInURL({ view: 'change-move-order-status', id: this.selectedRecordId, order_id: this.selectedOrderId, order_move_status: 'InProcess' });
            }
            else if (action == 'MoveCompleted') {
                this.orderMoveStatusForm.reset();
                this.showOrderMoveStatusChangedForm = true;
                this.orderMoveStatusForm.status = 'Completed';
                this.addViewInURL({ view: 'change-move-order-status', id: this.selectedRecordId, order_id: this.selectedOrderId, order_move_status: 'Completed' });
            }
            else if (action == 'MoveCanceled') {
                this.orderMoveStatusForm.reset();
                this.showOrderMoveStatusChangedForm = true;
                this.orderMoveStatusForm.status = 'Canceled';
                this.addViewInURL({ view: 'change-move-order-status', id: this.selectedRecordId, order_id: this.selectedOrderId, order_move_status: 'Canceled' });
            }
        },
        toggleRecordMenu(event) {
            this.selectedRecordId = event.currentTarget.getAttribute('recordId');
            this.selectedOrderId = event.currentTarget.getAttribute('orderId');
            this.$refs.menu.toggle(event);
        },
        loadForm() {
            this.selectedRecordId = 0;
            this.addViewInURL({ view: 'add-order', id: this.selectedRecordId });
            this.showForm = true;
        },
        closeForm() {
            if (this.orderView) {
                this.orderView = false;
                this.showForm = false;
                this.removeViewFromURL('view-order');
            }
            else {
                this.removeViewFromURL('add-order');
                this.showForm = false;
            }

            if (!this.selectedRecordId) {
                this.getRecords();
            }
        },
        async getOrderCategories() {
            var Vthis = this;
            Vthis.orderCategoriesLoading = true;
            await this.axios.post('getAllOrderCategories', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data) {
                            this.orderCategories = response.data;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    Vthis.orderCategoriesLoading = false;
                })

        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            var status = this.$route.query.status;
            this.filterForm.order_status = status;
            if (this.filterForm.save_search) {
                this.saveSearch();
            }
            this.axios.post('getOrdersMoves', { filters: this.filterForm })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.records = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        getSavedSearches() {
            this.savedSearchesLoading = true;
            this.axios.post('getSavedSearches', { 'module_name': 'orders' })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.savedSearches = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.savedSearchesLoading = false;
                })
        },
        saveSearch() {
            this.filterForm.post(this.$baseurl + 'api/saveSearch')
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response.data.SavedSearches)
                            this.savedSearches = response.data.SavedSearches;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        deleteSavedSearch(id = 0) {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    Vthis.axios.post('deleteSearch', { id: id })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.filterForm.reset();
                                Vthis.getSavedSearches();
                                Vthis.getRecords();
                                Vthis.showMessage('Saved Search deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });
        },
        viewRecord() {
            var Vthis = this;
            this.loading = true;
            this.axios.post('getAllOrders', { id: this.selectedRecordId })
                .then((response) => {
                    this.printResponseResult(response, false);
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        deleteRecord() {
            var Vthis = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirmation',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    Vthis.loading = true;
                    let DeleteAbleRecords = [];

                    if (Vthis.selectedRecords) {
                        Vthis.selectedRecordId = '';
                        Vthis.selectedRecords.forEach((data) => {
                            DeleteAbleRecords.push(data.id);
                        })
                    }
                    else {
                        DeleteAbleRecords.push(Vthis.selectedRecordId);
                    }

                    // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                    // filterdRecords.forEach((data) => {
                    //     DeleteAbleRecords.push(data.id);
                    // })  

                    Vthis.axios.post('deleteOrder', { id: DeleteAbleRecords })
                        .then((response) => {
                            let responseStatus = this.printResponseResult(response, false);
                            if (responseStatus == 200) {
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully', 'success');
                            }
                        })
                        .catch((error) => {
                            this.printResponseResult(error);
                        })
                        .finally(() => {
                            Vthis.loading = false;
                        })
                },
                reject: () => {
                    // this.loading = false;
                }
            });
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'order_number': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'user_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'agent_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'category_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'driver_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'vehicle_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'passangers': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'price': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'paid_amount': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'order_status': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'order_start_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'order_end_date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'company': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'added_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'modified_by': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'date_added': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'date_modification': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        },
        clearFilter() {
            this.initFilters();
            this.filterForm.reset();
            this.getRecords();
        },
        isRTL() {
            return this.$appState.RTL;
        }
    },
    watch: {
    }
}
</script>
 
